import React from "react"
import Blog from "../../../components/blog"

const Text = () => {
  return (
     <Blog>
      <h1 class="font-header font-black text-front text-2xl leading-none break-words mb-6">Dino</h1>
      <h2 class="mb-6 font-header font-black">10 March 2022</h2>
      <p>Dino dance.</p>
    </Blog>
    
  )
}

export default Text;